import React,{useState,useContext,lazy,Suspense} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {Loading} from "@gull"
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";
import axios from 'axios'
import {articleListErp} from 'app/columnsArticleListeDataTable'
import { IsAuthContext } from 'app/App';

const ModalWmsCsvSelectColumn = lazy(() => import("../modal/ModalWmsCsvSelectColumn"));

const  ExportArticleButton = (props) => {
    const t = useTranslate();
    const [exportCsvLoading,setExportCsvLoading] = useState(false)
    const [showModal,setShowModal] = useState(false)
    let sizeDatas = (props.sizeDatas) ? props.sizeDatas : 0

    const { authParam } = useContext(IsAuthContext);
    let wmsDatasColums = props.additionalData ? [...props.additionalData,...articleListErp] : articleListErp

    const goExportCsv = async (header) => {
        setShowModal(false)
        if(sizeDatas>process.env.REACT_APP_CSV_EXPORT_LIMIT){
            return(
                swal.fire({
                    icon: 'error',
                    title: t('impossibleExport'),
                    text: t('maxLineAuth',{limit:process.env.REACT_APP_CSV_EXPORT_LIMIT,actual:sizeDatas}),
                  })
               ) 
        }
        else{
            let head = (Array.isArray(header) && header.length==0) ? "" : header
            setExportCsvLoading(true)
            let theLang = (props.lang=='en') ? 'en' : 'fr'
            let obj = props.filtre
            obj.lang=theLang
            obj.header=head
            obj.mark=props.addMark
            if(props.clientId) obj['client']=props.clientId
            if(props.frompos) obj['frompos']=true
            const response = await axios.post(process.env.REACT_APP_API_URL+props.url,obj
                ).then((response) => {
                    setExportCsvLoading(false)
                    if(!response.data.valid){
                        return(
                            swal.fire({
                                icon: 'error',
                                title: t('impossibleExport'),
                                text: t('maxLineAuth',{limit:process.env.REACT_APP_CSV_EXPORT_LIMIT,actual:response.data.size}),
                            })
                        ) 
                    }
                    else{
                        return(
                            swal.fire({
                                icon: 'success',
                                title: t('csvWillBeReady')
                            })
                        ) 
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    setExportCsvLoading(false)
                    return(
                        swal.fire({
                            icon: 'error',
                            title: t('unknowError')
                        })
                    ) 
                })
        }
    }

    const handleClick = () => {
        if(props.module && props.module=='wms'){
            //Dans le wms, on va afficher une modal afin de pouvoir sélectionner les colonnes à exporter en csv

            if(props.header=='selection'){
                //Si selection est passé à header, on affiche la modal pour permettre la sélection des colonnes à exporter 
                setShowModal(true)
            }else{
                //Dans le cas ou all est passé, on prend toutes les colonnes que l'utilisateur peut voir et on les export 
                let arr = []
                wmsDatasColums.map(value=>{
                    let readAccess = authParam.access.find(access=>access.action == value.allowAccessName+'read')
                    
                    if(!readAccess) arr.push(value.dataField)
                })
                
                goExportCsv(arr.join(','))
            }
            
        }else{
            goExportCsv(props.header)
        }
    };

    return (<>
        {(props.module && props.module=='wms' && showModal) &&
            <Suspense fallback={<Loading></Loading>}>
                <ModalWmsCsvSelectColumn 
                    header={props.header}//header est soit "all" ou "sélection"
                    additionalData={props.additionalData}//représente les colonnes à afficher en plus des données de l'article
                    showModal={showModal}
                    setShowModal={setShowModal}
                    wmsDatasColums={wmsDatasColums}
                    initCol={props.initCol}//représente les colonnes à exporter par défaut avant uen possible sélection de l'utilisateur 
                    goExportCsv={goExportCsv}
                />
            </Suspense>
        }
        {(props.type=='button') ?
            <button disabled={exportCsvLoading} className={`btn btn-dark btn-sm ${(props.noMarginTop) ? '' : 'mb-2'}`} onClick={() => handleClick()}>
                {exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : props.title}
            </button>
            :
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {props.title}
                    </Tooltip>
                }
            >
                <span className="text-success" style={{cursor:'Pointer'}} onClick={() => handleClick()}>{exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <FontAwesomeIcon icon={faFileCsv} />}</span>
            </OverlayTrigger> 
        }
    </>)
}

export default ExportArticleButton;