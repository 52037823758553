import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import { ControlAccess} from "@gull";
import { Modal} from "react-bootstrap";

const BtnCollecteReceptionAllPos = (props) => {
    const t = useTranslate();
    const [validButton,setValidButton] = useState(false)
    const [showRestriction,setShowRestriction] = useState(false)

    const submitAll = async () => {
        setValidButton(true)
        const response = await axios.put(process.env.REACT_APP_API_URL+props.urlSubmit+props.docId, props.objSubmit
        ).then((response) => {
            if(!response.data.valid){
                swal.fire(t('unknowError'), "", "error");
            }else{
                props.reloadData(Date.now())
            }
            setValidButton(false)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
            setValidButton(false)
        })
    };

    const beforeSubmit = () => {
        if(!props.restriction.go){
            setShowRestriction(true)
        }else{
            submitAll()
        }
    };

    const callback = () => {
        setShowRestriction(false)
        props.setRestriction({...props.restriction,go:false})
        submitAll()
    };

    return(
        <div>
            <Modal show={showRestriction} onHide={() => setShowRestriction(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'80px'}}>
                        <ControlAccess section={props.sectionRection} restriction={props.restriction} moduleId={props.posid} setRestriction={props.setRestriction} callback={callback} focus={true}/>
                    </div>
                </Modal.Body>
            </Modal>
            <button 
                disabled={props.disabled || !props.zoneId || validButton}
                className='btn btn-info'
                onClick={() => {
                    swal
                    .fire({
                        title: props.confirmMsg,
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            beforeSubmit()
                        }
                    });
                }}
            >
                {validButton ? <span><div className="spinner-border spinner-border-sm"></div></span> : props.btnTitle}
            </button>
        </div>
    )
}

export default BtnCollecteReceptionAllPos;