import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ClientForm} from '@gull'
import { Modal } from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom'

const ClientDossier = (props) => {
    const t = useTranslate();
    const [show,setShow] = useState(false)
    const [client,setClient] = useState(props.folderDetail.client)
    const history = useHistory()

    const closeModal = () =>{
        setShow(false)
    }

    return(
        <div className='row'>
            <Modal size="xl" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('editClient')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientForm 
                        clientid={props.folderDetail.client_id}
                        setValue={setClient}
                        closeModal={closeModal}
                        b2b={true}
                    />
                </Modal.Body>
            </Modal>
            <span className='col d-flex'>
                {props.linkOnCLient ?
                    <div className='lien-to' onClick={()=>history.push('/invoice/client/'+client.id)}>{client.name+' '+client.firstname}</div>
                :
                    <div>{client.name+' '+client.firstname}</div>
                }
                &nbsp;&nbsp;
                <FontAwesomeIcon className='text-primary pointer' icon={faEdit} onClick={()=>setShow(true)}/>
            </span>
        </div>
    )
}

export default ClientDossier;