import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {BtnEditField} from '@gull'

const MemoDossier = (props) => {
    const t = useTranslate();
    const [valueInput,setValueInput] = useState(props.folderDetail.memo)
    const [validButton,setValidButton] = useState(false)

    const handleChange = (e) => {
        setValueInput(e.target.value)
    };

    const handleSubmit = () => {
        let data={memo:valueInput}
        props.onSubmit(setValidButton,data)
    };

    return(
        <div className='row'>
            {props.onEdit.memo ?
                <span className='col-11'>
                    <div style={{width:'100%'}}>
                        <textarea rows='10' disabled={validButton} value={valueInput} className='form-control' onChange={(e)=>handleChange(e)}></textarea>
                    </div>&nbsp;&nbsp;
                    <div>
                        <button disabled={validButton} className="btn btn-sm  btn-primary" onClick={()=>handleSubmit()}>
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </div>
                </span>
            :
                false
            }
            <span className='col d-flex'>
                <BtnEditField isFor='memo' allowAccessName='false' onEdit={props.onEdit} handleEdit={props.handleEdit} />&nbsp;&nbsp;
                {!props.onEdit.memo && <span>{props.folderDetail.memo}</span>}
            </span>
        </div>
    )
}

export default MemoDossier;