import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso, ExportComptabilite} from '@gull'

const ExportFormCompta = (props) => {
    const t = useTranslate();
    const [valueDate,setValueDate] = useState({
        begin:'',
        end:''
    })

    const handleChange = (e,name) => {
        setValueDate({...valueDate,[name]:e.target.value})
    };

    return(
        <CardPerso header="Export">
            <div className="row mb-2">
                <div className="col-12 form-group">
                    <label>{t('beginningPeriod')}</label>
                    <input value={valueDate.begin} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'begin')}/>
                </div>
                <div className="col-12 form-group">
                    <label>{t('endPeriod')}</label>
                    <input value={valueDate.end} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'end')}/>
                </div>
            </div>
            <div className='row mb-1' style={{marginLeft:'0.1em', marginRight:'0.1em'}}>
                <ExportComptabilite disabled={(valueDate.begin=='' || valueDate.end=='') ? true : false} setReload={props.setReload} title={t('saleExport')} type="button" url={"api/compta/sage/export_ventes/"+valueDate.begin+'/'+valueDate.end}></ExportComptabilite>
            </div>
            <div className='row' style={{marginLeft:'0.1em', marginRight:'0.1em'}}>
                <ExportComptabilite title={t('thirdPartyExport')} type="button" url={"api/compta/sage/export_tiers"}></ExportComptabilite>
            </div>
        </CardPerso>
    )
}

export default ExportFormCompta;