import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {BtnEditField} from '@gull'

const NameDossier = (props) => {
    const t = useTranslate();
    const [valueInput,setValueInput] = useState(props.folderDetail.name)
    const [validButton,setValidButton] = useState(false)

    const handleChange = (e) => {
        setValueInput(e.target.value)
    };

    const handleSubmit = () => {
        let data={name:valueInput}
        props.onSubmit(setValidButton,data)
    };

    return(
        <div className='row'>
            {props.onEdit.name ?
                <span className='col-10 d-flex'>
                    <div style={{width:'100%'}}>
                        <input disabled={validButton} value={valueInput} className='form-control' onChange={(e)=>handleChange(e)}/>
                    </div>&nbsp;&nbsp;
                    <div>
                        <button disabled={validButton} className="btn btn-sm  btn-primary" onClick={()=>handleSubmit()}>
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </div>
                </span>
            :
                false
            }
            <span className='col d-flex'>
                {!props.onEdit.name && <h3>{props.folderDetail.name}</h3>}&nbsp;&nbsp;
                <BtnEditField isFor='name' allowAccessName='false' onEdit={props.onEdit} handleEdit={props.handleEdit} />
            </span>
        </div>
    )
}

export default NameDossier;