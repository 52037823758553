import React, {useState, useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import axios from 'axios'
import swal from "sweetalert2";

const useLoad = (posid) => {
    const t = useTranslate();
    const[zones,setZones] = useState([]);
    const[loading,setLoading] = useState(true)

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/zone/getall/"+posid
            ).then((response) => {
                let arr = []
                for(var i=0; i<response.data.datas.length; i++){
                    if(response.data.datas[i].type=='reception'){
                        arr.push(response.data.datas[i])
                    }
                    if(response.data.datas[i].type=='stockage'){
                        arr.push(response.data.datas[i])
                    }
                    if(response.data.datas[i].type=='commerciale'){
                        arr.push(response.data.datas[i])
                    }
                    if(response.data.datas[i].type=='commande'){
                        arr.push(response.data.datas[i])
                    }
                }
                setZones(arr)
                setLoading(false)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [posid])

    return [zones,loading];
}

const SelectZoneStock = (props) =>{
    const t = useTranslate();
    const [zones,loading] = useLoad(props.posid)

    useEffect(function () {
        if(props.to && props.to.fzone_id!=null && props.to.picked_status==2 && props.to.closed==0) props.setZoneReceiveCollecte(props.to.fzone_id)
    }, [props.to])

    const majZoneReception = async (zoneId) => {
        props.setLoadingChangeMode(true)
        const res  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/to/"+props.to.id, {
            fzone:zoneId
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
                props.setLoadingChangeMode(false)
            }else{
                props.setTo(response.data.to)
                props.setLoadingChangeMode(false)
                props.setZoneReceiveCollecte(zoneId)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status==403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            }
            props.setLoadingChangeMode(false)
        })
    };
    
    const handleChangeZone = (e) =>{
        props.setZoneReceiveCollecte(e.target.value)
        if(props.to.picked_status==2 && props.to.status==0) majZoneReception(e.target.value)
    }

    return(
        <div className="form-group"> 
            <select value={props.zoneReceiveCollecte} disabled={loading || props.loadingChangeMode} className='form-control' onChange={(e)=>handleChangeZone(e)}>
                <option value="">-- Select --</option>
                {zones.map(value=>{
                    if(value.havebins==0){
                        return(
                            <option key={value.id} value={value.id}>{value.name}</option>
                        )
                    }
                })}
            </select>
            <label>{props.labelMsg}</label>
        </div>                      
    )
}

export default SelectZoneStock;