import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {constructionDocument} from '@gull'
import { useParams } from "react-router-dom";
import axios from 'axios'
import swal from "sweetalert2";

const CancelDiscount =  (props) =>{
    const t =useTranslate()
    const { posid } = useParams();
    const idsLocalStorage= (props.module=='pos') ? localStorage.getItem("pos").split(',') : [0,0]
    const [validButton,setValidButton]=useState(false)

    const cancelDiscount = async () => {
        let obj = {
            cancel_remise:true
        }
        if(props.forGroup) obj.group_detail_id=props.groupId
        else obj.detail_id=props.item.id
        
        if(props.module=='pos') obj.id = props.cookies.current_ticket

        if(props.module=='facturation'){
            constructionDocument(setValidButton,props.id,callBackConstruct,t,obj,props.setItemsBilling,props.setAllInfos)
        }else{
            setValidButton(true)
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    setValidButton(false)
                }
                else{   
                    setValidButton(false)
                    props.closeModal('price')
                    props.setCurrentTicket(response.data.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
                setValidButton(false)
            })
        }
    }

    const callBackConstruct = (response) => {
        props.closeModal('price')
    };

    return(
        <>
            <div className="row text-center">
                <button disabled={validButton} className="btn btn-danger" onClick={() => cancelDiscount()}>{t('btnCancelDiscount')}</button>
            </div>
        </>
    )
}

export default CancelDiscount;