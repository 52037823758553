import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ControlAccess, SuccessCollecteReceiptAll} from '@gull'
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import axios from 'axios'

const BtnCollecteReceptionAllWms = (props) =>{
    const t = useTranslate();
    const [show,setShow] = useState(false)
    const [section,setSection]=useState(false)
    const [loading,setLoading]=useState(true) 
    const [zoneId,setZoneId]=useState('') 
    const [showRestriction,setShowRestriction]=useState(false)
    
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+props.shopId
            ).then((response) => {
                setSection(response.data)
                setLoading(false)
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
                setLoading(false)
                swal.fire(messageError, "", "error");
            })
        })()
    }, [])

    const beforeSubmit = () => {
        if(!props.restriction.go){
            setShowRestriction(true)
        }else{
            props.handleClick('all',zoneId,setShow)
        }
    };

    const callback = () => {
        setShowRestriction(false)
        props.setRestriction({...props.restriction,go:false})
        props.handleClick('all',zoneId,setShow)
    };

    return(<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{props.allTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {showRestriction && 
                        <div style={{marginTop:'80px'}} className='mb-4'>
                            <ControlAccess section={props.section} restriction={props.restriction} moduleId={props.shopId} setRestriction={props.setRestriction} callback={callback} focus={true} />
                        </div>
                    }
                    <div className='form-group mb-3'>
                        <label>{t('selectAStorageArea')}</label>
                        <select disabled={showRestriction} value={zoneId} className='form-control' onChange={(e)=>setZoneId(e.target.value)}>
                            <option value="">-- Select --</option>
                            {section && section.zone.map(value=>{
                                if(value.havebins==0){
                                    return(
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <button 
                        className='btn btn-primary'
                        disabled={showRestriction || zoneId=='' || props.validButton}
                        onClick={() => beforeSubmit()}
                    >
                        {props.validButton ? <span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </>
            </Modal.Body>
        </Modal>
        {loading ? 
            <span className='d-flex justify-content-center mt-5 mb-5'><div className="spinner-border spinner-border-sm"></div></span>
        :
            props.finished ? 
                <SuccessCollecteReceiptAll message={props.successMessage} wmsid={props.shopId} path={props.pathRedirect}/>
            :
                <>
                    <div className="d-grid gap-2 mb-2">
                        <button 
                            className="btn btn-danger" 
                            disabled={props.validButton}
                            type="button"
                            onClick={()=>setShow(true)}
                        >
                            {props.allTitle}
                        </button>
                    </div>
                    <div className="d-grid gap-2 mb-2">
                        <button 
                            className="btn btn-primary" 
                            disabled={props.validButton}
                            type="button"
                            onClick={() => props.handleClick('single',false,false)}
                        >
                            {props.singleTitle}
                        </button>
                    </div>
                </>
        }
        
    </>)
}

export default BtnCollecteReceptionAllWms;