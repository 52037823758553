import React,{useState,useContext,useEffect,useRef} from "react";
import {PaymentCompleted,FormatedPrice,ActionClientBarLeft} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import PaymentFormRight from './Component/PaymentFormRight'
import RefundForm from './Component/RefundForm'
import OrderForm from './Component/OrderForm'
import OrderFormNext from './Component/OrderFormNext'
import { IsAuthContext } from 'app/App';
import { useParams } from "react-router-dom";
import { modePaiementCb } from 'app/modePaiementCb';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import GenerateDevis from "./Component/Devis/GenerateDevis";
import MainPaymentView from './PaymentView/MainPaymentView'

const PaymentForm = (props) =>{
    const t =useTranslate()
    const { posid } = useParams();
    const {authParam} = useContext(IsAuthContext);
    const [giftCardScan,setGiftCardScan]=useState(false)
    const [valueDue,setValueDue]=useState(0)
 
    const [showTabFidelite,setShowTabFidelite]=useState(false)  
    const [monnaieRendu,setMonnaieRendu]=useState(0)
    const [showDevisPrint,setShowDevisPrint]=useState(false) 

    const searchInputClient = useRef('')
    const [load,setLoad]=useState({
        caissier:false,
        client:false
    })
    const modulePos = authParam.modules.find(value=>value.name=='pos')
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    useEffect(function () {
        let versement = 0
        
        Object.keys(props.currentTicket).length!= 0 && props.currentTicket.paiement_records.map(item=>{
            versement+=item.montant
            
        })

        let newDue = +props.totalAchat - +versement
        //ici dans le cas d'un remboursement par carte cadeau dû à un réglement par une catégorie 'other' on force le restant dû à etre à 0 et non un chiffre négatif
        if(giftCardScan){
            if(+newDue < 0){
                setValueDue(0)
            }
            setGiftCardScan(false)
        }else{
            // if(newDue>2){//Si il reste 1f ou 2f à payer à cause de l'arrondi à l'inférieur, on passe le valueDue à 0 
            //     setValueDue(newDue)
            // }else{
            //     setValueDue(0)
            // }
            setValueDue(newDue)  
        }

    }, [props.currentTicket])

    const asignDefaultModePay = () => {
        if(props.section.advanceparams && props.section.advanceparams.hasOwnProperty('modal_paiement') && props.section.advanceparams.modal_paiement=='tab_square'){
            props.setCurrentPayment('card')
        }else{
            props.setCurrentPayment('espece')
        }
    };

    useEffect(function () {
        if(props.clientFideliteInfo && props.clientFideliteInfo.data.length!=0){//Le client à le droit de bénéficier de ses points de fidélité 
            if(props.clientFideliteInfo.mode_fidelite=='cashback'){
                //Si on est en cachback, on vérifie si un seuil minimum de cashbakc à été configuré pour afficher ou non le mode depaiement fidélité
                if(!props.seuilCashBack){
                    setShowTabFidelite(true);
                }else if(props.seuilCashBack && +props.clientFideliteInfo.valeur_cfp >= +props.seuilCashBack){
                    setShowTabFidelite(true);
                }else{
                    setShowTabFidelite(false);
                }
                asignDefaultModePay()
            }else if(props.clientFideliteInfo.mode_fidelite=='recompense'){
                //ici on récupère tous les ids des articles possible à offrir en récompense fidélité 
                let arrIdsFreeItems = Object.values(props.clientFideliteInfo.data).flat()
                // ici on récupère tous les articles du ticket qui font parti de la liste des récompenses
                let arrItemsRecompense = []
                props.currentTicket.ticket_detail.map(value=>{
                    if(value.article && arrIdsFreeItems.includes(value.article.id)){
                        for(let i=0; i< +value.quantity; i++){
                            arrItemsRecompense.push(value)
                        }
                    }
                })

                let allFidelitePayment = props.currentTicket.paiement_records.filter(value=>value.mode=='recompense')

                if(arrItemsRecompense.length!=0){
                    //On vérifie si dans notre panier on a des articles qui font parti du programme de fidélité
                    if(arrItemsRecompense.length == allFidelitePayment.length){
                        asignDefaultModePay()
                        setShowTabFidelite(false)
                    }else{
                        // props.setCurrentPayment('fidelite')
                        setShowTabFidelite(true);
                    }
                }else{
                    asignDefaultModePay()
                    setShowTabFidelite(false)
                }
            }else if(props.clientFideliteInfo.mode_fidelite=='giftcard'){
                //ici on récupère tous les montants des cartes cadeau dispo en récompense
                let arrGcRecompense = Object.values(props.clientFideliteInfo.data)
                //ici on récupère toutes les cartes cadeau qui ont été ajoutées au ticket si il y en a
                let allGiftCardOnReceipt = props.currentTicket.ticket_detail.filter(value=>value.special=='gift_card')
                //Ici on récupère uniquement les cartes cadeau du ticket oû le montant fait parti des carte cadeau dispo en récompense
                let giftCardWithFideliteMatch = [] 
                allGiftCardOnReceipt.map(value=>{
                    if(arrGcRecompense.includes(value.prix_remise.toString())){
                        giftCardWithFideliteMatch.push(value.prix_remise)
                    }
                })
                //Ici on vérifie si sur les cartes cadeau ou la carte cadeau du panier correspond à une des cartes cadeau offert par le programme de ficélité 
                //Si le client à le droit à une carte cadeau de 2000XPF et qu'il y a une carte cadeau de 5000xpf dans son panier, on ne propose pas le paiement par fidélité 

                let allFidelitePayment = props.currentTicket.paiement_records.filter(value=>value.mode=='fidelite')

                if(giftCardWithFideliteMatch.length!=0){
                    //On vérifie si il y a des cartes cadeaux qui correspondent a une récompense du programme de fidélité
                    // props.setCurrentPayment('fidelite')
                    // setShowTabFidelite(true);

                    if(giftCardWithFideliteMatch.length == allFidelitePayment.length){
                        asignDefaultModePay()
                        setShowTabFidelite(false)
                    }else{
                        // props.setCurrentPayment('fidelite')
                        setShowTabFidelite(true);
                    }

                }else{
                    asignDefaultModePay()
                    setShowTabFidelite(false)
                }
            }
        }else{
            asignDefaultModePay()
            setShowTabFidelite(false)
        }

    }, [props.clientFideliteInfo])

    const updateCurentTicket = async (isFor,id) =>{//fonction exécutée si un ticket est en cours 
        props.setBtnDisabled(true)
        let data={
            id:props.cookies.current_ticket,
            client_id:id
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoad({
                    ...load,
                    client:false
                })
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        props.setBtnDisabled(false)
    }

    let currentShop = authParam.sections.find(shop=>shop.id == posid)

    let cbPaymentMethod = []
    let otherPaymentMethod = []

    if(currentShop.modepaiement!=null){
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si le mode de paiement par cb est autorisé sur ce shop
            if(modePaiementCb.includes(currentShop.modepaiement[i])){
                cbPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si les autres modes de paiement sont autorisés sur ce shop
            if(!modePaiementDefaultAll.includes(currentShop.modepaiement[i])){
                otherPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    }

    const changePayMethod = (k)=>{
        props.setCurrentPayment(k)
    }
    
    let total = props.totalAchat

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;
    
    return(
        <div className="row">
            {(props.ticketCommande) ?
                <OrderFormNext 
                    ticketCommande={props.ticketCommande}
                    setTicketCommande={props.setTicketCommande}
                    devise={devise}
                    digit={digit}
                    posid={posid}
                    from='formSaleOrder'
                    setModalTitle={props.setModalTitle}
                    setShow={props.setShow}
                />
            :
                (props.orderForm) ?
                    <OrderForm 
                        currentTicket={props.currentTicket}
                        authParam={authParam}
                        currentShop={currentShop}
                        setTicketCommande={props.setTicketCommande}
                        resetSale={props.resetSale}
                        posid={posid}
                        devise={devise}
                        setOrderForm={props.setOrderForm}
                        setCookie={props.setCookie}
                        expire_date={props.expire_date}
                        actualShop={props.actualShop}
                        setRelanceCommande={props.setRelanceCommande}
                        allCommandes={props.allCommandes}
                        setAllCommandes={props.setAllCommandes}
                    />
                :
                    (showDevisPrint) ?
                        <GenerateDevis 
                            currentTicket={props.currentTicket} 
                            setShowDevisPrint={setShowDevisPrint} 
                            setBtnDisabled={props.setBtnDisabled} 
                            setModalTitle={props.setModalTitle}
                            setCookie={props.setCookie}
                            expire_date={props.expire_date}
                            setCurrentTicket={props.setCurrentTicket}
                            resetSale={props.resetSale}
                            countItems={props.countItems}
                            devise={devise}
                        />
                    :
                    (currentShop.modepaiement!=null) ?
                        <>
                            {(props.currentTicket.status==1) ? 
                                <div className="col-6">
                                    <div className="row">
                                        {(modulePos.params.client.active) &&
                                            <ActionClientBarLeft 
                                                style={`position-relative col-10 mb-4`}
                                                setValueInputClient={props.setValueInputClient}
                                                valueInputClient={props.valueInputClient}
                                                updateCurentTicket={updateCurentTicket}
                                                cookies={props.cookies}
                                                load={load}
                                                currentTicket={props.currentTicket}
                                                setClientId={props.setClientId}
                                                from='ParymentFormLeft'
                                                module='pos'//à passer pour obligatoirement pour connaitre les options de son module 
                                                searchInputClient={searchInputClient}
                                                posid={posid}
                                                clientId={props.clientId}
                                                setCookie={props.setCookie}
                                                setCurrentTicket={props.setCurrentTicket} 
                                                section={props.section}
                                            />
                                        }
                                    </div>
                                    <PaymentCompleted />
                                    <div className="mt-5">
                                        <h3 >{t('giveChange')+' : '} <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={monnaieRendu} />}</strong></h3>
                                    </div>
                                </div>
                            :
                                (total<0) ?
                                    <div className="col-6">
                                        <RefundForm
                                            total={Math.abs(total)}
                                            cookies={props.cookies}
                                            currentTicket={props.currentTicket}
                                            setCurrentTicket={props.setCurrentTicket}
                                            clientId={props.clientId}
                                            posid={posid}
                                            modulePos={props.modulePos}
                                        />
                                    </div>
                                :
                                    (props.currentPayment) ?
                                        <div className="col-6">
                                            <MainPaymentView 
                                                monnaieRendu={monnaieRendu}
                                                setMonnaieRendu={setMonnaieRendu}
                                                total={total}
                                                cookies={props.cookies}
                                                clientId={props.clientId}
                                                currentTicket={props.currentTicket}
                                                setCurrentTicket={props.setCurrentTicket}
                                                devise={devise}
                                                digit={digit}
                                                currentPayment={props.currentPayment}
                                                changePayMethod={changePayMethod}
                                                valueDue={valueDue}
                                                posid={posid}
                                                authParam={authParam}
                                                giftCardScan={giftCardScan}
                                                setGiftCardScan={setGiftCardScan}
                                                btnDisabled={props.btnDisabled}
                                                setBtnDisabled={props.setBtnDisabled}
                                                affichageClient={props.affichageClient}
                                                section={props.section}
                                                currentShop={currentShop}
                                                cbPaymentMethod={cbPaymentMethod}
                                                otherPaymentMethod={otherPaymentMethod}
                                                company={props.company}

                                                //Que pour les cheques
                                                modulePos={props.modulePos}

                                                //Pour le paiement par fidélité
                                                clientFideliteInfo={props.clientFideliteInfo}
                                                setClientFideliteInfo={props.setClientFideliteInfo}
                                                setShowBtnFidelite={props.setShowBtnFidelite}
                                                showTabFidelite={showTabFidelite}
                                                padsRefs={props.padsRefs}
                                            />
                                        </div>
                                    :false
                            }
                            <div className="col-6 d-flex flex-column justify-content-between">
                                <PaymentFormRight
                                    cookies={props.cookies}
                                    setCurrentTicket={props.setCurrentTicket}
                                    posid={posid}
                                    total={total}
                                    devise={devise}
                                    digit={digit}
                                    valueDue={valueDue}
                                    id='paymentDue'
                                    currentTicket={props.currentTicket}
                                    setCookie={props.setCookie}
                                    expire_date={props.expire_date}
                                    setOrderForm={props.setOrderForm}
                                    setModalTitle={props.setModalTitle}
                                    setTicketCommande={props.setTicketCommande}
                                    resetSale={props.resetSale}
                                    btnDisabled={props.btnDisabled}
                                    setBtnDisabled={props.setBtnDisabled}
                                    modulePos={props.modulePos}
                                    restrictionRemoveLinePayment={props.restrictionRemoveLinePayment}
                                    setRestrictionRemoveLinePayment={props.setRestrictionRemoveLinePayment}
                                    section={props.section}
                                    pointsFidelite={props.pointsFidelite}
                                    setPointsFidelite={props.setPointsFidelite}
                                    setClientFideliteInfo={props.setClientFideliteInfo}
                                    setShowBtnFidelite={props.setShowBtnFidelite}
                                    setShowDevisPrint={setShowDevisPrint}
                                    setMonnaieRendu={setMonnaieRendu}
                                    padsRefs={props.padsRefs}
                                />
                            </div>
                        </>
                    :
                        <span className="text-center">{t('tableNoModePaiement')}</span>
            }
        </div>
    )
}

export default PaymentForm;