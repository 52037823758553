import React, {useState, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso, DeleteActionButton} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axios from 'axios'
import swal from "sweetalert2";
import {scrollXDataTable} from 'app/scrollXDataTable';
import Moment from 'react-moment';

const useLoadInit = (reload) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [histoExportPeriode,setHistoExportPeriode]=useState([])
    const [filtre,setFiltre] = useState({})

    useEffect(function () {
        (async function() {
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/task_histo/get_all",filtre
            ).then((response) => {
                setHistoExportPeriode(response.data.data)
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading(false)
        })()
    }, [reload,filtre])

    return [loading,histoExportPeriode,setFiltre,setHistoExportPeriode]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            noDataIndication={t('tableNoData')}
            wrapperClasses="table-responsive"
        />
    );
};

const HistoExportPeriode = (props) => {
    const t = useTranslate();
    const [loading,histoExportPeriode,setFiltre,setHistoExportPeriode] = useLoadInit(props.reload)
    const [perPage, setPerPage] = useState(50);
    const [valueDate,setValueDate] = useState({
        begin:'',
        end:''
    })

    const handleChange = (e,name) => {
        setValueDate({...valueDate,[name]:e.target.value})
    };

    const submitFiltre = (name) => {
        if(name=='filtre'){
            setFiltre({
                begin:valueDate.begin,
                end:valueDate.end
            })
        }else{
            setFiltre({})
        }
    };
    
    const deleteExportRecord = async (exportId) =>{
        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/task_histo/delete/"+exportId
        ).then((response) => {
            let newData = histoExportPeriode.filter(value=>value.id!=exportId)
            setHistoExportPeriode(newData)
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let datas = new Array
    histoExportPeriode.forEach((value,ind)=>{
        let obj = {
            id: value.id,
            begin: <Moment format="DD/MM/YYYY">{value.begin}</Moment>,
            end: <Moment format="DD/MM/YYYY">{value.end}</Moment>,
            date: <Moment format="DD/MM/YYYY">{value.created_at}</Moment>,
            action:
                <span className="d-flex justify-content-between">
                   <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete='' customClickEvent={deleteExportRecord}/>
                </span>,
            index: ind + 1,
        }
        
        datas.push(obj)
    })

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: datas.length
    };

    let columns =[
        {
            dataField: "action",
            text: "Actions",
            editable: false,
        },
        {
            dataField: "date",
            text: t('exportDate'),
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "begin",
            text: t('beginningPeriod'),
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "end",
            text: t('endPeriod'),
            sort: true,
            headerSortingStyle,
        },
    ];
    
    return (
        <CardPerso header={t('historicalExportSales')}>
            {(loading) ? 
                <div className="d-flex justif-content-center mt-3"><div className="spinner-border spinner-border-sm"></div></div>
            :
                <small>
                    <ToolkitProvider
                    keyField="index"
                    data={ datas }
                    columns={ columns }
                    >
                    {(propst) => (
                        <>
                            <div className="row mb-2">
                                <div className="col-5 form-group">
                                    <label>{t('beginningPeriod')}</label>
                                    <input value={valueDate.begin} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'begin')}/>
                                </div>
                                <div className="col-5 form-group">
                                    <label>{t('endPeriod')}</label>
                                    <input value={valueDate.end} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'end')}/>
                                </div>
                                <div className="col">
                                    <button disabled={(valueDate.begin=='' || valueDate.end=='') ? true : false} className="btn btn-sm btn-primary mb-1" onClick={()=>submitFiltre('filtre')}>{t('btnValidate')}</button>&nbsp;
                                    <button className="btn btn-sm btn-warning mb-1" onClick={()=>submitFiltre('reset')}>Reset</button>
                                </div>
                            </div>
                            <div className="position-relative">
                                <CustomDataTable
                                    propst={propst}
                                    paginationOptions={paginationOptions}
                                    columns={columns}
                                    defaultSortDirection="asc"
                                />
                            </div>
                        </>
                    )}
                    </ToolkitProvider>
                </small>
            }
            
        </CardPerso>
    );
}

export default HistoExportPeriode;
